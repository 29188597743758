export function getStrapiMediaUrl(url) {
    if (url == null) {
        return null
    }

    // Return the full URL if the media is hosted on an external provider
    if (url.startsWith("http") || url.startsWith("//")) {
        return url
    }

    // Otherwise prepend the URL path with the Strapi URL
    return `${process.env.STRAPI_URL || "http://localhost:1337"}${url}`
}

export function getStrapiImages(imageData) {
    const data = imageData.data;
    const Images = [];
    if (Array.isArray(data)) {
        data.forEach((v) => {
            Images.push(setImageData(v));
        });
    } else {
        Images.push(setImageData(data));
    }
    return Images;
}

export function setImageData(image) {
    return {
        id: image.id,
        name: image.attributes.name,
        alt: image.attributes.alternativeText,
        caption: image.attributes.caption,
        url: {
            default: getStrapiMediaUrl(image.attributes.url),                      // Original size of image
            thumbnail: getStrapiMediaUrl(image.attributes.formats.thumbnail.url),  // fixed height of 156
            small: getStrapiMediaUrl(image.attributes.formats.small.url),          // fixed width of 500
            medium: getStrapiMediaUrl(image.attributes.formats.medium.url),        // fixed width of 750
            large: getStrapiMediaUrl(image.attributes.formats.large.url),          // fixed width of 1000
        }
    };
}