<template>
    <div>
        <header>
            <!-- Navbar -->
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">Navbar</a>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                        class="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a
                                    class="nav-link active"
                                    aria-current="page"
                                    href="#"
                                    >Home</a
                                >
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Link</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- Navbar -->
        </header>
        <div class="container">
            <div class="row mb-5">
                <div
                    class="col-sm-4"
                    v-bind:key="storeitem.id"
                    v-for="storeitem in storeitems"
                >
                    <div class="card m-auto mt-5" style="width: 18rem">
                        <img
                            :src="storeitem.images[0].url.thumbnail"
                            :alt="storeitem.images[0].alt"
                            class="img-thumbnail"
                            style="height: 200px"
                        />
                        <div class="card-body">
                            <h5 class="card-title">
                                {{ storeitem.title }}
                            </h5>
                            <p class="card-text">
                                {{ storeitem.description }}
                            </p>
                            <h5 class="card-title text-primary">
                                ₹{{ storeitem.price }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getStrapiImages } from "@/utils/media";

export default {
    name: "HelloWorld",
    components: {},
    data() {
        return {
            storeitems: [],
        };
    },
    mounted() {
        fetch("http://localhost:1337/api/storeitems?populate=*")
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                const arr = data.data;
                arr.forEach((v) => {
                    const item = {
                        id: v.id,
                        title: v.attributes.Title,
                        description: v.attributes.Description,
                        price: v.attributes.Price,
                        images: getStrapiImages(v.attributes.Image),
                    };
                    this.storeitems.push(item);
                });
                console.log(this.storeitems);
            });
    },
};
</script>
